import "./styles/index.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import LogRocket from "logrocket";
import moment from "moment";
import pkg from "../package.json";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { debugMode } from "./utilities/debugMode";
import { genRandomId } from "./utilities/genRandomId";

const container = document.getElementById("root");

const userId = localStorage.getItem("user_id") ?? genRandomId("user");
localStorage.setItem("user_id", userId);

console.log("version", pkg.version);
console.log(
  "debugMode",
  debugMode,
  "(add ?debug=1 or ?debug=0 to the URL to toggle debug mode)"
);
console.log("user", userId);

if (
  [
    "2024-09-30",
    "2024-10-01",
    "2024-10-02",
    "2024-10-03",
    "2024-10-04",
  ].includes(moment().format("YYYY-MM-DD"))
) {
  LogRocket.init("diq0zv/brainlock");
  LogRocket.identify(userId);
}

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
