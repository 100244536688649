import briefOverview from "../../assets/background_gradient.jpg";
import video from "../../assets/cutscenes/gate_open_560_1_HB.mp4";
// import lottieLine from "../../assets/lottie/arrowrow.json";
import lottieClick from "../../assets/lottie/interaction_frames.json";
// import lottieCircle from "../../assets/lottie/radial.json";
// import roomIndicator from "../../assets/lottie/room1.json";
import gateControllerGlow from "../../assets/room1/gate_cont_LIGHT.png";
import doorLockDetail from "../../assets/room1/gate_controller.png";
import bgImage from "../../assets/room1/ROOM1.jpg";
import controller from "../../assets/room1/room1_controller.png";
import controllerGlow from "../../assets/room1/room1_controller_glow.png";
import doorLock from "../../assets/room1/room1_gate.png";
import roomGlow from "../../assets/room1/ROOM1_REFELCT.png";
import puzzle from "../../assets/room1/room1_unit_L.png";
import puzzleImage from "../../assets/shuffle/MAZE_puzzel_3bij3.png";
import solutionAsset from "../../assets/shuffle/shuffle_solution.png";
import {
  INVENTORY_CONTROLLER,
  INVENTORY_PUZZLE_1,
} from "../inventory/inventoryItems";

const content = {
  id: "start",
  type: "image",
  image: bgImage,
  size: {
    width: 3964,
  },
  objects: [
    {
      id: INVENTORY_CONTROLLER,
      type: "inventory",
      trigger: {
        image: controller,
        position: {
          top: 300,
          left: 935,
        },
      },
    },
    // {
    //   id: "roomIndicator",
    //   type: "lottie",
    //   animation: roomIndicator,
    //   noLoop: true,
    //   position: {
    //     top: -200,
    //     left: 0,
    //     width: 560,
    //   },
    // },
    {
      id: "controllerGlow",
      type: "animation",
      hideWhen: INVENTORY_CONTROLLER,
      trigger: {
        image: controllerGlow,
        position: {
          top: 188,
          left: 827,
        },
      },
    },
    {
      id: "puzzle",
      type: "scene",
      trigger: {
        image: puzzle,
        pulsing: true,
        position: {
          top: 288,
          left: 1686,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        // iOS performance issues
        // {
        //   id: "greenCircle",
        //   type: "lottie",
        //   hideWhen: INVENTORY_PUZZLE_1,
        //   animation: lottieCircle,
        //   position: {
        //     top: 8,
        //     left: 40,
        //     width: 66,
        //   },
        // },
        // {
        //   id: "greenLights",
        //   type: "lottie",
        //   hideWhen: INVENTORY_PUZZLE_1,
        //   animation: lottieLine,
        //   position: {
        //     top: 25,
        //     left: 300,
        //     width: 222,
        //   },
        // },
        {
          id: "solution",
          type: "imageAsset",
          showWhen: INVENTORY_PUZZLE_1,
          image: solutionAsset,
          position: {
            top: 25,
            left: 60,
          },
        },
        {
          id: "miniGames",
          type: "slidepuzzle",
          image: puzzleImage,
          position: {
            top: 115,
            left: 30,
          },
        },
      ],
    },
    {
      id: "doorLock",
      type: "scene",
      locked: INVENTORY_CONTROLLER,
      trigger: {
        pulsing: true,
        image: doorLock,
        position: {
          top: 283,
          left: 3228,
        },
      },
      detail: {
        image: doorLockDetail,
        size: {
          width: 560,
        },
      },
      objects: [
        {
          id: "controller",
          type: "gateController",
          code: ["RIGHT", "UP", "RIGHT", "LEFT", "DOWN"],
          video,
          success: {
            type: "redirect",
            page: "/room2",
          },
        },
        {
          id: "controllerGlow",
          type: "animation",
          trigger: {
            image: gateControllerGlow,
            position: {
              top: 0,
              left: 0,
            },
          },
        },
      ],
    },
    {
      id: "clickAnimation",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 355,
        left: 1786,
        width: 56,
      },
    },
    {
      id: "roomGlow",
      type: "animation",
      trigger: {
        image: roomGlow,
        isSlow: true,
        position: {
          top: 0,
          left: 0,
          width: 3964,
        },
      },
    },
  ],
};

export default content;
