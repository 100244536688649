import cn from "classnames";
import Lottie from "lottie-react";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getUnlocked } from "../../../store/reducers";
import styles from "./lottieAsset.module.scss";

function LottieAsset({ content, handleComplete, clickHandler, loop = true }) {
  const { position, animation, hideWhen } = content;
  const unlockedArray = useSelector(getUnlocked);
  const shouldBeHidden = unlockedArray.includes(hideWhen);
  const [hide, setHide] = useState(false);

  const animationData = useMemo(() => {
    return JSON.parse(JSON.stringify(animation));
  }, [animation]);

  if (shouldBeHidden) {
    return null;
  }

  function onComplete() {
    setHide(true);
    if (handleComplete && typeof handleComplete === "function") {
      handleComplete();
    }
  }

  const animationClasses = cn(styles.animation, {
    [styles.isClickable]: clickHandler,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={animationClasses} style={position} onClick={clickHandler}>
      {!hide && (
        <Lottie
          animationData={animationData}
          loop={loop && !content.noLoop}
          autoplay={handleComplete && typeof handleComplete === "function"}
          onComplete={(event) => onComplete(event)}
        />
      )}
    </div>
  );
}

export default LottieAsset;
