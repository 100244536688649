import { Howl } from "howler";

import effectsAudio from "../assets/audioSprite_brainlock.mp3";
import soundSprite from "../content/sounds.json";

export const SOUND_EFFECT_WRONG = "wrong";
export const SOUND_EFFECT_SUCCESS = "tada";
export const SOUND_EFFECT_PICKUP = "tok";
export const SOUND_EFFECT_BACKGROUND = "ambient";
export const SOUND_EFFECT_CALLING = "ring";
export const SOUND_EFFECT_CLICK = "click";
export const SOUND_EFFECT_MESSAGE = "message";
export const SOUND_EFFECT_DESTROY = "destroy";

let backgroundSound;
let telephoneRingingSound;

const restructureSprite = () => {
  const spriteObject = {};
  soundSprite.map((sound) => {
    spriteObject[sound.id] = [sound.start, sound.length];
    return sound;
  });

  return spriteObject;
};

function playSoundEffect(type) {
  const sound = new Howl({
    src: [effectsAudio],
    sprite: restructureSprite(soundSprite),
  });

  sound.play(type);
}

function playBackgroundSound() {
  return false;

  /*
  const bgSprite = soundSprite.find(
    (sound) => sound.id === SOUND_EFFECT_BACKGROUND
  );

  if (bgSprite) {
    if (!backgroundSound) {
      const spriteObject = {};
      spriteObject[SOUND_EFFECT_BACKGROUND] = [
        bgSprite.start,
        bgSprite.length,
        true,
      ];
      backgroundSound = new Howl({
        src: [effectsAudio],
        sprite: spriteObject,
        volume: 0.2,
      });
    }

    backgroundSound.play(SOUND_EFFECT_BACKGROUND);

  }
      */
}

function stopBackgroundSound() {
  if (backgroundSound) backgroundSound.stop();
}

function telephoneRinging() {
  const callingSprite = soundSprite.find(
    (sound) => sound.id === SOUND_EFFECT_CALLING
  );

  if (callingSprite) {
    if (!telephoneRingingSound) {
      const spriteObject = {};
      spriteObject[SOUND_EFFECT_CALLING] = [
        callingSprite.start,
        callingSprite.length,
        true,
      ];
      telephoneRingingSound = new Howl({
        src: [effectsAudio],
        sprite: spriteObject,
        volume: 1,
      });
    }
    telephoneRingingSound.play(SOUND_EFFECT_CALLING);
  }
}

function stopTelephoneRinging() {
  if (telephoneRingingSound) telephoneRingingSound.stop();
}

const soundEffects = {
  playSoundEffect,
  playBackgroundSound,
  stopBackgroundSound,
  telephoneRinging,
  stopTelephoneRinging,
};

export default soundEffects;
