import { useState } from "react";
import pkg from "../../../../package.json"

import styles from "./startCodeInput.module.scss";

function StartCodeInput({ onCorrect }) {
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  function onSubmit() {
    if (inputValue === "1357") {
      onCorrect();
    } else {
      setError(true);
    }
  }

  function onChange(event) {
    setInputValue(event.target.value);
    setError(false);
  }

  return (
    <div className={styles.startCodeInputContainer}>
      <p>Ben je klaar om de controle te nemen? Stap in het mysterie nu!</p>
      <div className={styles.form}>
        <label htmlFor="code">Vul de code in</label>
        <div className={styles.inputContainer}>
          <input value={inputValue} maxLength={5} onChange={onChange} />
        </div>
        <button type="submit" onClick={onSubmit}>
          Bevestig
        </button>
      </div>
      {error && <p className={styles.error}>Helaas, deze code is fout</p>}
      <div className={styles.versionNumber}>{pkg.version}</div>
    </div>
  );
}

export default StartCodeInput;
