const changeDebugMode = new URLSearchParams(window.location.search).get(
  "debug"
);

if (changeDebugMode === "0") {
  localStorage.removeItem("debugMode");
} else if (changeDebugMode === "1") {
  localStorage.setItem("debugMode", "1");
}

export const debugMode = Boolean(localStorage.getItem("debugMode") || "");
