import Lottie from "lottie-react";
import { useEffect } from "react";

import animation from "../../../assets/lottie/5sec_timer.json";
import soundEffects, {
  SOUND_EFFECT_WRONG,
} from "../../../utilities/soundEffects";
import styles from "./errorOverlay.module.scss";

function ErrorOverlay({ onComplete }) {
  useEffect(() => {
    soundEffects.playSoundEffect(SOUND_EFFECT_WRONG);

    const timeoutId = setTimeout(() => {
      if (onComplete) {
        onComplete();
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [onComplete]);

  return (
    <div className={styles.errorOverlay}>
      <Lottie
        animationData={JSON.parse(JSON.stringify(animation))}
        className={styles.animation}
        loop={false}
        onComplete={onComplete}
      />
    </div>
  );
}

export default ErrorOverlay;
