import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { setRoomTime } from "../../../store/actions/app";
import {
  setInventoryAnimationEvent,
  setPhoneEvent,
} from "../../../store/actions/events";
import { getInventory } from "../../../store/reducers";
import { fetchImageURLListFromContent } from "../../../utilities/getImagesFromContent";
import { preloadImages } from "../../../utilities/preloadImages";
import Interactives from "../../interactives/interactives";
import Loader from "../../ui/loader/loader";
// import Menu from "../../ui/menu/menu";
import styles from "./imageRoom.module.scss";

function ImageRoom({ content }) {
  const roomEl = useRef(null);
  const containerEl = useRef(null);
  const imageAssetRef = useRef(null);
  const dispatch = useDispatch();
  const timer = useRef();
  const { timeline } = content;
  const timelineInterval = useRef(null);
  const timelineState = useRef(!!timeline && [...timeline]);

  const [loadedTime, setLoadedTime] = useState(null);

  const navigate = useNavigate();
  const inventory = useSelector(getInventory);

  useEffect(() => {
    if (timelineInterval) clearTimeout(timelineInterval.current);
    // Is this the solution?
  }, []);

  useEffect(() => {
    dispatch(setRoomTime());
  }, [dispatch]);

  useEffect(() => {
    function setTimeLineProgress() {
      timelineInterval.current = setInterval(() => {
        const millisecondsPassed = new Date().getTime() - loadedTime;
        if (timelineState.current.length > 0) {
          const timelineItemIndex = timelineState.current.findIndex(
            (timelineItem) => timelineItem.time < millisecondsPassed / 1000
          );
          if (timelineItemIndex !== -1) {
            const { type } = timelineState.current[timelineItemIndex].dispatch;
            const { payload } =
              timelineState.current[timelineItemIndex].dispatch;
            switch (type) {
              case "phone":
                dispatch(setPhoneEvent(payload));
                break;
              default:
                break;
            }
            timelineState.current.splice(timelineItemIndex, 1);
          }
        }
      }, 300);
    }

    if (timeline && timeline.length > 0 && loadedTime) {
      setTimeLineProgress();
    }
    return () => {
      if (timelineInterval) clearInterval(timelineInterval.current);
    };
  }, [timeline, loadedTime, dispatch]);

  useEffect(() => {
    if (!loadedTime) {
      const imageList = fetchImageURLListFromContent(content);
      preloadImages(imageList).then(() => {
        setLoadedTime(new Date().getTime());
      });
    }
  }, [content, loadedTime]);

  function goToNextRoom(room, requiredInventory) {
    if (requiredInventory) {
      if (requiredInventory.length !== inventory.length) {
        timer.current = setTimeout(() => {
          dispatch(setInventoryAnimationEvent("error"));
        }, 800);

        return false;
      }
    }

    return navigate(room);
  }

  function onCodeUnlocked(success) {
    switch (success.type) {
      case "redirect":
        goToNextRoom(success.page, success.required);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(`NO ACTION FOR TYPE ${success.type}`);
        break;
    }
  }

  return (
    <div ref={containerEl} className={styles.roomContainer}>
      <div
        ref={roomEl}
        className={styles.room}
        style={{
          width: content.size.width ? `${content.size.width}px` : `auto`,
        }}
      >
        <img
          ref={imageAssetRef}
          src={content.image}
          alt=""
          className={styles.roomAsset}
          style={{
            width: content.size.width ? `${content.size.width}px` : `auto`,
          }}
        />
        <Interactives
          objects={content.objects}
          goToNextRoom={goToNextRoom}
          onCodeUnlocked={onCodeUnlocked}
        />
        {!loadedTime && (
          <div className={styles.loadingScreen}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageRoom;
