import briefOverview from "../../assets/background_gradient.jpg";
import video from "../../assets/cutscenes/outro_560_HB.mp4";
import lottieArrows from "../../assets/lottie/arrowrow.json";
import lottieLine from "../../assets/lottie/cubes3.json";
import lottieClick from "../../assets/lottie/interaction_frames.json";
import LottieLineScreen from "../../assets/lottie/lines.json";
// import roomIndicator from "../../assets/lottie/room5.json";
import gateControllerGlow from "../../assets/room1/gate_cont_LIGHT.png";
import doorLockDetail from "../../assets/room1/gate_controller.png";
import doorLock from "../../assets/room1/room1_gate.png";
import buttonVideo from "../../assets/room5/knopY_gevonden_HB.mp4";
import bgImage from "../../assets/room5/ROOM5.jpg";
import roomGlow from "../../assets/room5/ROOM5_REFLECT.png";
import questionFormMinigameTrigger from "../../assets/room5/room5_unit_L.png";
import wordPulseHintTrigger from "../../assets/room5/room5_unit_R.png";
import {
  INVENTORY_CONTROLLER,
  INVENTORY_PUZZLE_2_BUTTON_A,
  INVENTORY_PUZZLE_2_BUTTON_B,
  INVENTORY_PUZZLE_2_BUTTON_X,
  INVENTORY_PUZZLE_2_BUTTON_Y,
} from "../inventory/inventoryItems";

const content = {
  id: "start",
  type: "image",
  image: bgImage,
  size: {
    width: 3964,
  },
  objects: [
    // {
    //   id: "roomIndicator",
    //   type: "lottie",
    //   animation: roomIndicator,
    //   noLoop: true,
    //   position: {
    //     top: -200,
    //     left: 0,
    //     width: 560,
    //   },
    // },
    {
      id: "questionFormMinigame",
      type: "scene",
      trigger: {
        image: questionFormMinigameTrigger,
        pulsing: true,
        position: {
          top: 291,
          left: 1037,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "lineScreen",
          type: "lottie",
          animation: LottieLineScreen,
          position: {
            top: 0,
            left: 0,
            width: 560,
          },
        },
        {
          id: "greenLights",
          type: "lottie",
          animation: lottieArrows,
          position: {
            top: 36,
            left: 286,
            width: 222,
          },
        },
        {
          id: "cubes",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 30,
            left: 50,
            width: 135,
          },
        },
        {
          id: "questionForm",
          type: "questionForm",
          position: {
            top: 115,
            left: 30,
          },
        },
        {
          id: "buttonBEarned",
          type: "videoAsset",
          showWhen: INVENTORY_PUZZLE_2_BUTTON_Y,
          video: buttonVideo,
          closeOnEnd: true,
          position: {
            top: 0,
            left: 0,
          },
        },
      ],
    },
    {
      id: "clickAnimation1",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 351,
        left: 1135,
        width: 56,
      },
    },
    {
      id: "doorLock",
      type: "scene",
      locked: INVENTORY_CONTROLLER,
      trigger: {
        pulsing: true,
        image: doorLock,
        position: {
          top: 283,
          left: 3228,
        },
      },
      detail: {
        image: doorLockDetail,
        size: {
          width: 560,
        },
      },
      objects: [
        {
          id: "controller",
          type: "gateController",
          code: [
            INVENTORY_PUZZLE_2_BUTTON_A,
            INVENTORY_PUZZLE_2_BUTTON_B,
            INVENTORY_PUZZLE_2_BUTTON_X,
            INVENTORY_PUZZLE_2_BUTTON_Y,
            INVENTORY_PUZZLE_2_BUTTON_X,
          ],
          video,
          success: {
            type: "redirect",
            page: "/end",
          },
        },
        {
          id: "controllerGlow",
          type: "animation",
          trigger: {
            image: gateControllerGlow,
            position: {
              top: 0,
              left: 0,
            },
          },
        },
      ],
    },
    {
      id: "wordPulseHint",
      type: "scene",
      trigger: {
        image: wordPulseHintTrigger,
        pulsing: true,
        position: {
          top: 261,
          left: 1971,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "lineScreen",
          type: "lottie",
          animation: LottieLineScreen,
          position: {
            top: 0,
            left: 0,
            width: 560,
          },
        },
        {
          id: "wordPulseHint",
          type: "wordPulseHint",
          position: {
            top: 0,
            left: 0,
          },
        },
      ],
    },
    {
      id: "clickAnimation2",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 318,
        left: 2046,
        width: 56,
      },
    },
    {
      id: "roomGlow",
      type: "animation",
      trigger: {
        image: roomGlow,
        isSlow: true,
        position: {
          top: 0,
          left: 0,
          width: 3964,
        },
      },
    },
  ],
};

export default content;
