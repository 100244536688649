// import download from "downloadjs";
// import { toJpeg } from "html-to-image";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EndBackground from "../../assets/end/end.png";
import Logo from "../../assets/end/logo.png";
import SkipLogo from "../../assets/ui/skip_productie.png";
import { setEndTime } from "../../store/actions/app";
// import contentFail from "../../content/cutscenes/cutscene_fail";
// import contentSuccess from "../../content/cutscenes/cutscene_success";
import {
  getEndTime,
  getObjectValues,
  getPersonalGoal,
  getStartTime,
} from "../../store/reducers";
import { secondsToClockTime } from "../../utilities/timeHelpers";
import styles from "./end.module.scss";

function End() {
  const [totalSeconds, setTotalSeconds] = useState(-1);
  const [totalSecondsTime, setTotalSecondsTime] = useState(-1);
  const [formValues, setFormValues] = useState(-1);

  const polaroidRef = useRef();
  const [downloadScreenshot, setDownloadScreenshot] = useState(false);
  const dispatch = useDispatch();
  const startTime = useSelector(getStartTime);
  const endTime = useSelector(getEndTime);
  const objectValues = useSelector(getObjectValues);
  const personalGoal = useSelector(getPersonalGoal);

  useEffect(() => {
    dispatch(setEndTime());
  }, [dispatch]);

  useEffect(() => {
    if (totalSeconds !== -1) {
      setTotalSecondsTime(secondsToClockTime(totalSeconds));
    } else {
      console.error("total seconds -1", startTime, endTime);
    }
  }, [endTime, startTime, totalSeconds]);

  useEffect(() => {
    if (startTime && endTime) {
      setTotalSeconds(endTime - startTime);
    }
  }, [startTime, endTime]);

  useEffect(() => {
    const formValuesFromStore = objectValues.find(
      (item) => item.id === "FORM_VALUES"
    );
    if (formValuesFromStore) {
      setFormValues(formValuesFromStore.value);
    }
  }, [objectValues]);

  function downloadPolaroid() {
    setDownloadScreenshot(true);
  }

  // useEffect(() => {
  //   if (downloadScreenshot && polaroidRef.current) {
  //     toJpeg(polaroidRef.current).then((dataUrl) => {
  //       download(dataUrl, "Check-je-resultaat.jpg");
  //       setDownloadScreenshot(false);
  //     });
  //   }
  // }, [downloadScreenshot, setDownloadScreenshot]);
  return (
    <div className={styles.endContainer}>
      <div className={styles.toDownload}>
        <img src={EndBackground} alt="" className={styles.endClockImage} />
        <div className={styles.endClockCounter}>
          <h3>EINDTIJD</h3>
          {totalSecondsTime}
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles.endClockDownload} onClick={downloadPolaroid}>
        Bekijk jouw doel
      </div>
      {downloadScreenshot && (
        <div ref={polaroidRef} className={styles.resultForm}>
          <div className={styles.background} />
          <div className={styles.scroller}>
            <img src={Logo} alt="logo" className={styles.brainLogo} />
            <h2 className={styles.mainGoal}>
              MIJN PERSOONLIJK DOEL
              <span>{personalGoal}</span>
            </h2>
            <h2>
              Ik wil mijn persoonlijke doel halen omdat -
              <span>{formValues?.question1}</span>
            </h2>
            <h2>
              Het kan voor mij moeilijk zijn, door -
              <span>{formValues?.question2}</span>
            </h2>
            <h2>
              Maar op deze manier zorg ik ervoor dat het mij wél gaat lukken -
              <span>{formValues?.question3}</span>
            </h2>
            <h2>
              Wie kan mij helpen bij het behalen van mijn persoonlijk doel -
              <span>{formValues?.question4}</span>
            </h2>
            <h2>
              Een handige eigenschap van mij die helpt mijn doel te halen, is -
              <span>{formValues?.question5}</span>
            </h2>
            <h2>
              Om me goed te voelen, ga ik-
              <span>{formValues?.question6}</span>
            </h2>
            <p>
              Maak jij je wel eens zorgen over je blowgedrag of dat van anderen?
              Check dan....
            </p>
            <a
              href="https://www.skipdietrip.nl/brainlock"
              target="_blank"
              rel="noreferrer"
            >
              <img src={SkipLogo} alt="logo" className={styles.logo} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default End;
