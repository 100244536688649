import { Outlet } from "react-router-dom";

// eslint-disable-next-line import/no-cycle
import Hint from "../components/hint/hint";
// eslint-disable-next-line import/no-cycle
import DebugMenu from "../components/ui/debugMenu/debugMenu";
import DisableBackButton from "../components/ui/disableBackButton/disableBackButton";
import { debugMode } from "../utilities/debugMode";

function Page() {
  return (
    <>
      <Hint />
      <Outlet />
      <DisableBackButton />
      {debugMode && <DebugMenu />}
    </>
  );
}

export default Page;
