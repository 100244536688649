import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Typewriter from "typewriter-effect";

import TextBalloonAsset from "../../../assets/chatGame/text_balloon.png";
import LottieLines from "../../../assets/lottie/lines.json";
import ArrowAsset from "../../../assets/ui/arrow-right.png";
import { INVENTORY_PUZZLE_2_BUTTON_B } from "../../../content/inventory/inventoryItems";
import { setUnlocked } from "../../../store/actions/app";
import ErrorOverlay from "../../ui/errorOverlay/errorOverlay";
import LottieAsset from "../lottieAsset/lottieAsset";
import styles from "./chatGame.module.scss";

const initialQuestions = [
  {
    question: "Hee, we zijn met z’n allen aan het blowen. Join je?",
    answers: [
      "Nee thanks",
      "Ik weet het niet, ik twijfel. We kunnen ook wat anders gaan doen?",
    ],
    rightAnswerIndex: -1,
    selectedAnswerIndex: -1,
  },
  {
    question: "Hoezo? 1 hijsje kan toch geen kwaad? ",
    answers: ["Nee man, ik wil scherp blijven", "Oke, laat me proberen dan"],
    rightAnswerIndex: 0,
    selectedAnswerIndex: -1,
  },
  {
    question: "Maar echt iedereen blowt?",
    answers: [
      "Nah, dat is echt bullshit. De meeste van onze leeftijd hebben nog nooit gesmoked",
      "Boeie, ik wil gewoon niet",
    ],
    rightAnswerIndex: -1,
    selectedAnswerIndex: -1,
  },
  {
    question: "Ah, doe niet zo saai",
    answers: [
      "Eén hijsje dan...",
      "Hoezo saai? Ik heb het niet nodig om het naar mijn zin te hebben.",
    ],
    rightAnswerIndex: 1,
    selectedAnswerIndex: -1,
  },
  {
    question: "Okay, true. Waarom skip je smoken eigenlijk?",
    answers: [
      "Ik wil niet dat zo iets gevolgen heeft voor mn toekomst",
      "Ik wil gewoon gezond en fit blijven",
    ],
    rightAnswerIndex: -1,
    selectedAnswerIndex: -1,
  },
  {
    question:
      "Aii, ik snap het. Zullen we dan iets anders doen? Oh trouwens, ik vond net zo'n vaag knopje. Misschien heb jij er iets aan?",
    answers: ["Thanks, die had ik nodig!"],
    rightAnswerIndex: -1,
    selectedAnswerIndex: -1,
  },
];

function Question({
  ownIndex,
  question,
  onSelectAnswer,
  selectedAnswerIndex,
  currentQuestionIndex,
  error,
}) {
  const [showAnswers, setShowAnswers] = useState(false);
  const questionStyles = cn(styles.question, {
    [styles.error]: error,
  });
  useEffect(() => {
    setShowAnswers(false);
  }, [currentQuestionIndex, setShowAnswers]);

  return (
    <div className={questionStyles}>
      {currentQuestionIndex === ownIndex && (
        <>
          <div className={styles.p}>
            <span className={styles.friendLabel}>Vriend-</span>
            <Typewriter
              options={{ delay: 80 }}
              onInit={(typewriter) => {
                typewriter
                  .typeString(question.question)
                  .callFunction(() => {
                    setShowAnswers(true);
                  })
                  .start();
              }}
            />
          </div>
          <img src={TextBalloonAsset} alt="" className={styles.textBalloon} />
        </>
      )}

      {showAnswers && (
        <div className={styles.answers}>
          <button
            type="button"
            className={selectedAnswerIndex === 0 ? styles.selected : ""}
            onClick={() => onSelectAnswer(0)}
          >
            <span>{question.answers[0]}</span>
          </button>
          {question.answers[1] && (
            <button
              className={selectedAnswerIndex === 1 ? styles.selected : ""}
              type="button"
              onClick={() => onSelectAnswer(1)}
            >
              <span>{question.answers[1]}</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
}

function ChatGame() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState(false);
  const [openQuestion, setOpenQuestion] = useState("");

  const [questions, setQuestions] = useState(initialQuestions);
  const timer = useRef();

  const dispatch = useDispatch();

  const SCREEN_WIDTH = 560;

  function reset() {
    setQuestions(initialQuestions);
    setAnswers([]);
    setCurrentQuestionIndex(0);
  }

  function earnButton() {
    dispatch(setUnlocked(INVENTORY_PUZZLE_2_BUTTON_B));
  }

  function onSelectAnswer(answer) {
    const tempAnswers = [...answers];
    const questionsArray = [...questions];

    tempAnswers.push({ answerIndex: currentQuestionIndex, answer });
    questionsArray[currentQuestionIndex].selectedAnswerIndex = answer;

    setAnswers(tempAnswers);
    setQuestions(questionsArray);

    if (
      questionsArray[currentQuestionIndex].rightAnswerIndex === -1 ||
      questionsArray[currentQuestionIndex].rightAnswerIndex === answer
    ) {
      if (currentQuestionIndex !== questionsArray.length - 1) {
        timer.current = setTimeout(() => {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        }, 300);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    } else {
      setError(true);
      timer.current = setTimeout(() => {
        setError(false);
        reset();
      }, 5000);
    }
  }

  return (
    <div className={styles.chatGame}>
      <LottieAsset
        content={{
          animation: LottieLines,
          position: { top: 0, left: 0, width: 560 },
        }}
      />

      <div className={styles.questionCarousel}>
        <div
          className={styles.questionContainer}
          style={{
            transform: `translateX(-${SCREEN_WIDTH * currentQuestionIndex}px)`,
          }}
        >
          {questions.map((question, key) => (
            <Question
              key={key}
              ownIndex={key}
              question={question}
              selectedAnswerIndex={question.selectedAnswerIndex}
              currentQuestionIndex={currentQuestionIndex}
              error={error}
              onSelectAnswer={onSelectAnswer}
            />
          ))}
          <div className={styles.questionFormSlide}>
            <div className={styles.form}>
              <h2>
                HOE ZEG JE NEE ALS IEMAND VRAAGT OF JE OOK EEN HIJSJE WILT?{" "}
              </h2>
              <textarea
                placeholder="..."
                onChange={(event) => {
                  setOpenQuestion(event.target.value);
                }}
              />
              <button
                className={styles.submit}
                type="submit"
                disabled={openQuestion.length === 0}
                onClick={earnButton}
              >
                <img alt="submit" src={ArrowAsset} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {error && <ErrorOverlay />}
    </div>
  );
}

export default ChatGame;
