import { useEffect } from "react";
import { useNavigate } from "react-router";

function DisableBackButton() {
  const navigate = useNavigate();

  useEffect(() => {
    // function removeBackButton(event) {
    //   window.history.go(1);
    //   event.preventDefault();
    //   event.stopPropagation();
    //   return false;
    // }
    // window.addEventListener("popstate", removeBackButton);
    // return () => window.removeEventListener("popstate", removeBackButton);
  }, [navigate]);

  return null;
}

export default DisableBackButton;
