import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import ImageRoom from "../../components/rooms/imageRoom/imageRoom";
import StartCodeInput from "../../components/ui/startCodeInput/startCodeInput";
import content from "../../content/rooms/start";
import { setStartTime } from "../../store/actions/app";

function Start() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(resetStore());
  });

  function handleCorrect() {
    dispatch(setStartTime());
    navigate(content.redirect);
  }

  return (
    <>
      <ImageRoom content={content} />
      <StartCodeInput onCorrect={handleCorrect} />
    </>
  );
}

export default Start;
