import briefOverview from "../../assets/background_gradient.jpg";
import video from "../../assets/cutscenes/gate_open_560_4_HB.mp4";
import lottieArrows from "../../assets/lottie/arrowrow.json";
import lottieLine from "../../assets/lottie/cubes3.json";
import lottieClick from "../../assets/lottie/interaction_frames.json";
import LottieLineScreen from "../../assets/lottie/lines.json";
// import roomIndicator from "../../assets/lottie/room4.json";
import gateControllerGlow from "../../assets/room1/gate_cont_LIGHT.png";
import doorLockDetail from "../../assets/room1/gate_controller.png";
import buttonVideo from "../../assets/room4/knopX_gevonden_HB.mp4";
import redRoomAsset from "../../assets/room4/ROOM4.jpg";
import blueRoomAsset from "../../assets/room4/ROOM4_BLUE.jpg";
import doorLockBlue from "../../assets/room4/room4_BLUE_gate.png";
import hintGlow from "../../assets/room4/room4_BLUE_hint_00000.png";
import hintTriggerBlue from "../../assets/room4/room4_blue_unit_L.png";
import roomLightsTriggerBlue from "../../assets/room4/room4_blue_unit_M.png";
import activitiesMinigameTriggerBlue from "../../assets/room4/room4_blue_unit_R.png";
import greenRoomAsset from "../../assets/room4/ROOM4_GROEN.jpg";
import doorLockGreen from "../../assets/room4/room4_groen_gate.png";
import hintTriggerGreen from "../../assets/room4/room4_groen_unit_L.png";
import roomLightsTriggerGreen from "../../assets/room4/room4_groen_unit_M.png";
import activitiesMinigameTriggerGreen from "../../assets/room4/room4_groen_unit_R.png";
import roomGlow from "../../assets/room4/ROOM4_REFLECT.png";
import doorLockRed from "../../assets/room4/room4_rood_gate.png";
import hintTriggerRed from "../../assets/room4/room4_rood_unit_L.png";
import roomLightsTriggerRed from "../../assets/room4/room4_rood_unit_M.png";
import activitiesMinigameTriggerRed from "../../assets/room4/room4_rood_unit_R.png";
import {
  INVENTORY_CONTROLLER,
  INVENTORY_PUZZLE_2_BUTTON_X,
  INVENTORY_PUZZLE_4_ROOMS_GREEN_ON,
  INVENTORY_PUZZLE_4_ROOMS_RED_ON,
} from "../inventory/inventoryItems";

const content = {
  id: "start",
  type: "image",
  image: blueRoomAsset,
  size: {
    width: 3964,
  },
  objects: [
    // {
    //   id: "roomIndicator",
    //   type: "lottie",
    //   animation: roomIndicator,
    //   noLoop: true,
    //   position: {
    //     top: -200,
    //     left: 0,
    //     width: 560,
    //   },
    // },
    {
      id: "controllerGlow",
      type: "animation",
      trigger: {
        image: hintGlow,
        position: {
          top: 233,
          left: 2364,
        },
      },
    },
    {
      id: "hintBlue",
      type: "scene",
      trigger: {
        image: hintTriggerBlue,
        pulsing: true,
        position: {
          top: 262,
          left: 864,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "hintDetail",
          type: "hintCarousel",
          position: {
            top: 115,
            left: 53,
          },
        },
        {
          id: "lineScreen",
          type: "lottie",
          animation: LottieLineScreen,
          position: {
            top: 0,
            left: 0,
            width: 560,
          },
        },
        {
          id: "greenLights",
          type: "lottie",
          animation: lottieArrows,
          position: {
            top: 36,
            left: 286,
            width: 222,
          },
        },
        {
          id: "cubes",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 30,
            left: 50,
            width: 135,
          },
        },
        {
          id: "cubes2",
          type: "lottie",
          animation: lottieLine,
          position: {
            top: 418,
            left: 267,
            width: 135,
          },
        },
      ],
    },
    {
      id: "doorLockBlue",
      type: "scene",
      locked: INVENTORY_CONTROLLER,
      trigger: {
        pulsing: true,
        image: doorLockBlue,
        position: {
          top: 285,
          left: 3226,
        },
      },
      detail: {
        image: doorLockDetail,
        size: {
          width: 560,
        },
      },
      objects: [
        {
          id: "controller",
          type: "gateController",
          code: ["UP", "RIGHT", "UP", "UP", INVENTORY_PUZZLE_2_BUTTON_X],
          video,
          success: {
            type: "redirect",
            page: "/room5",
          },
        },
        {
          id: "controllerGlow",
          type: "animation",
          trigger: {
            image: gateControllerGlow,
            position: {
              top: 0,
              left: 0,
            },
          },
        },
      ],
    },
    {
      id: "roomLightsBlue",
      type: "scene",
      trigger: {
        image: roomLightsTriggerBlue,
        pulsing: true,
        position: {
          top: 342,
          left: 1558,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "roomLights",
          type: "roomLights",
          position: {
            top: 0,
            left: 0,
          },
        },
      ],
    },
    {
      id: "activitiesMinigameTriggerBlue",
      type: "scene",
      trigger: {
        image: activitiesMinigameTriggerBlue,
        pulsing: true,
        position: {
          top: 292,
          left: 1830,
        },
      },
      detail: {
        image: briefOverview,
        size: {
          width: 560,
          height: 840,
        },
      },
      objects: [
        {
          id: "activitiesMinigame",
          type: "activitiesMinigame",
          position: {
            top: 0,
            left: 0,
          },
        },
        {
          id: "buttonXEarned",
          type: "videoAsset",
          showWhen: INVENTORY_PUZZLE_2_BUTTON_X,
          video: buttonVideo,
          closeOnEnd: true,
          position: {
            top: 0,
            left: 0,
          },
        },
      ],
    },
    {
      id: "redRoom",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_RED_ON,
      image: redRoomAsset,
      position: {
        top: 0,
        left: 0,
      },
    },
    {
      id: "hintRed",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_RED_ON,
      image: hintTriggerRed,
      pulsating: true,
      position: {
        top: 262,
        left: 864,
      },
    },
    {
      id: "doorLockRed",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_RED_ON,
      image: doorLockRed,
      pulsating: true,
      position: {
        top: 285,
        left: 3226,
      },
    },
    {
      id: "roomLightsRed",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_RED_ON,
      image: roomLightsTriggerRed,
      pulsating: true,
      position: {
        top: 342,
        left: 1558,
      },
    },
    {
      id: "activitiesMinigameTriggerRed",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_RED_ON,
      image: activitiesMinigameTriggerRed,
      pulsating: true,
      position: {
        top: 292,
        left: 1830,
      },
    },
    {
      id: "greenRoom",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_GREEN_ON,
      image: greenRoomAsset,
      position: {
        top: 0,
        left: 0,
      },
    },
    {
      id: "hintGreen",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_GREEN_ON,
      image: hintTriggerGreen,
      pulsating: true,
      position: {
        top: 262,
        left: 864,
      },
    },
    {
      id: "doorLockGreen",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_GREEN_ON,
      image: doorLockGreen,
      pulsating: true,
      position: {
        top: 285,
        left: 3226,
      },
    },
    {
      id: "roomLightsGreen",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_GREEN_ON,
      image: roomLightsTriggerGreen,
      pulsating: true,
      position: {
        top: 342,
        left: 1558,
      },
    },
    {
      id: "activitiesMinigameTriggerGreen",
      type: "imageAsset",
      showWhen: INVENTORY_PUZZLE_4_ROOMS_GREEN_ON,
      image: activitiesMinigameTriggerGreen,
      pulsating: true,
      position: {
        top: 292,
        left: 1830,
      },
    },
    {
      id: "clickAnimation1",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 320,
        left: 943,
        width: 56,
      },
    },
    {
      id: "clickAnimation2",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 374,
        left: 1642,
        width: 56,
      },
    },
    {
      id: "clickAnimation3",
      type: "lottie",
      animation: lottieClick,
      position: {
        top: 338,
        left: 1912,
        width: 56,
      },
    },
    {
      id: "roomGlow",
      type: "animation",
      trigger: {
        image: roomGlow,
        isSlow: true,
        position: {
          top: 0,
          left: 0,
          width: 3964,
        },
      },
    },
  ],
};

export default content;
